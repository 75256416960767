export const parseStatusText = (text?: string): string => {
  switch (text) {
    case ("NEW"):
      return "Новая";
    case ("SEARCH_RESPONSIBLE"):
      return "Поиск исполнителя";
    case ("BOOKED"):
      return "Назначена";
    case ("IN_PROGRESS"):
      return "В работе";
    case ("PAUSE"):
      return "Приостановлена";
    case ("FIN_TRUE"):
      return "Исполнена";
    case ("FIN_FALSE"):
      return "Не исполнена";
    case ("1C_SYNC"):
      return "Синхронизация с 1С";
    case ("1C_SUCCESS"):
      return "Отправлено в 1С";
    case ("1C_ERROR"):
      return "Ошибка 1С";
    default:
      return "";
  }
};
