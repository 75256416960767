import React, { FC, JSX } from "react";
import css from "../TasksListing.module.css";
import { FilterNamesType, FilterTasksType, ResponsibleFilterType } from "app/types";
import { Tag } from "antd";
import {
  CatalogFilterType,
  taskClientsTypeKey,
  taskStatusKey,
  taskTypeKey,
  taxSystemKey
} from "../../../../utils/catalog";

interface ITasksListingTagsProps {
  filterData: FilterTasksType | null;
  deleteResponsibleFilterData: (
    e: React.MouseEvent<HTMLElement>,
    key: string,
    id: string,
    value: ResponsibleFilterType[]
  ) => void;
  deleteStatusFilterData: (
    e: React.MouseEvent<HTMLElement>,
    key: string,
    filterStatus: string,
    value: string[],
  ) => void;
  deleteFilterType: (
    e: React.MouseEvent<HTMLElement>,
    key: string,
    typeVariant: string,
    value: string[],
  ) => void;
  deleteFilterData: (e: React.MouseEvent<HTMLElement>, key: string) => void;
  FilterNames: FilterNamesType;
  clearFilterData: (e: React.MouseEvent<HTMLElement>) => void;
}

const TasksListingTags: FC<ITasksListingTagsProps> = ({
  filterData,
  deleteResponsibleFilterData,
  deleteStatusFilterData,
  deleteFilterType,
  deleteFilterData,
  FilterNames,
  clearFilterData
}): JSX.Element => {
  const TagRender = ({
    keyData,
    value,
    id = keyData,
    name = "",
    responsible = false,
    type = "",
    status = ""
  }: {
    keyData: string;
    value: string | number | string[] | ResponsibleFilterType[] | null;
    id?: string;
    name?: string;
    responsible?: boolean;
    type?: string;
    status?: string;
  }): JSX.Element => {
    const valueData = (): string => {
      if (status || type || responsible) return name as string;
      
      return value as string;
    };
    const expired: boolean = keyData === "is_expired";
    
    const handlerMap: Record<string, (e: React.MouseEvent<HTMLElement>) => void> = {
      "status": (e: React.MouseEvent<HTMLElement>) => deleteStatusFilterData(e, keyData, status, value as string[]),
      "type": (e: React.MouseEvent<HTMLElement>) => deleteFilterType(e, keyData, type, value as string[]),
      "responsible": (e: React.MouseEvent<HTMLElement>) =>
        deleteResponsibleFilterData(e, keyData, id, value as ResponsibleFilterType[]),
      "default": (e: React.MouseEvent<HTMLElement>) => deleteFilterData(e, keyData)
    };
    
    const filtersVariants = (): string => {
      if (status) return "status";
      if (type) return "type";
      if (responsible) return "responsible";
   
      return "default";
    };
    
    const variantOfFilter: string = filtersVariants();

    return (
      <Tag
        key={id}
        className={`${css.commonTagStyle} ${css.alignedTag}`}
        closable
        onClose={handlerMap[variantOfFilter]}
      >
        <div className={css.tagBlock}>
          <div className={expired ? css.tagValue : css.tagKey}>{FilterNames[keyData]}</div>
          <div className={`${css.tagValue} ml-1`}>{valueData()}</div>
        </div>
      </Tag>
    );
  };

  return (
    <div className={css.tag}>
      {Object.entries(filterData ?? {}).map(
        ([key, value]: [string, string | number | string[] | ResponsibleFilterType[] | null]) => {
          const isGeneralOption: boolean = key === "task_type_lookup"
            || key === "clients_lookup.tax_system"
            || key === "clients_lookup.short_name";
          
          const lookupKeys = (type: string): string => {
            const filtersKeys: CatalogFilterType = {
              "task_type_lookup": taskTypeKey[type],
              "clients_lookup.tax_system": taxSystemKey[type],
              "clients_lookup.short_name": taskClientsTypeKey[type],
            };
            
            return filtersKeys[key];
          };
          
          if (!value) {
            return null;
          }

          if (key === "responsible_reference.uuid" && Array.isArray(value)) {
            return (value as ResponsibleFilterType[]).map((responsible: ResponsibleFilterType) => (
              <TagRender
                key={responsible.id}
                keyData={key}
                value={value}
                id={responsible.id}
                name={responsible.name}
                responsible
              />
            ));
          }
          
          if (isGeneralOption && Array.isArray(value)) {
            return (value as string[]).map((type: string) => (
              <TagRender
                key={type}
                keyData={key}
                name={lookupKeys(type)}
                value={value}
                type={type}
              />
            ));
          }
          
          if (key === "status_task" && Array.isArray(value)) {
            return (value as string[]).map((status: string) => (
              <TagRender
                key={status}
                keyData={key}
                name={taskStatusKey[status]}
                value={value}
                status={status}
              />
            ));
          }

          return (
            <TagRender key={key} keyData={key} value={value} />
          );
        })
      }
      <Tag className={`${css.commonTagStyle} ${css.tagButton}`} onClick={clearFilterData}>
        Сбросить фильтры
      </Tag>
    </div>
  );
};

export default TasksListingTags;