import React, {
  FC,
  JSX,
  SetStateAction,
  useEffect,
  useState
} from "react";
import {
  Form,
  Input,
  message,
  Modal,
  Select,
  Skeleton,
  UploadFile,
  UploadProps
} from "antd";
import css from "./ModalCreatAppeal.module.css";
import { ButtonCustom } from "../../../../../ui-kit/ButtonCustom/ButtonCustom";
import ZeroSearch from "../../../../ZeroSearch/ZeroSearch";
import DraggerForm from "../../../../../../utils/ModalUploadItems/DraggerForm/DraggerForm";
import { validFileFormat } from "../../../../../../utils/ModalUploadItems/validFileFormat";
import { UploadChangeParam } from "antd/es/upload";
import { deleteUploadFile, uploadFiles } from "../../../../../../api/document.api";
import { downloadFile, downloadUrl } from "../../../../../../utils/downloadFile";
import {
  UploadedFilesPropsType,
  TypicalRequestToClientType,
} from "app/types";
import { ReactComponent as AttachFileIcon } from "../../../../../../assets/icons/attach_file_icon.svg";
import { ReactComponent as DeleteDocument } from "../../../../../../assets/icons/delete_file.svg";
import { ReactComponent as Error } from "../../../../../../assets/icons/warning_circle_icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../../../../../reducers/mainReducer";
import { sendRequestToClientFailure, sendTypicalRequestToClient } from "../../../../../../actions/client.actions";
import { AppDispatch } from "../../../../../../store/store";
import { appealTopic } from "../contactingToClient";
import { messageSuccess, messageError } from "../../../../../../utils/MessageItems/MassageVariants";

interface IModalCreatAppealProps {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<SetStateAction<boolean>>;
}

interface IUploadedFilesProps {
  file_name: string;
  uuid: string;
}

interface IFormValuesProps {
  topicOfAppeal: string,
  textArea: string,
}

enum totalFiles {
  Total5 = 5
}

enum FileListing {
  Empty = 0
}

const maxSizeFile: number = 5;

const ModalCreatAppeal: FC<IModalCreatAppealProps> = ({
  isModalOpen,
  setIsModalOpen,
}): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  
  const { TextArea } = Input;
  const [form] = Form.useForm<IFormValuesProps>();
  const values: IFormValuesProps = Form.useWatch([], form);
  const {
    topicOfAppeal,
    textArea
  } = values || {};
  
  const {
    documentClient,
    requestToClientStatus,
  } = useSelector((state: AppStateType) => state.client);
  
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFilesPropsType[]>([]);
  const [uploadFilesLoader, setUploadFilesLoader] = useState<boolean>(false);
  
  useEffect(() => {
    if (requestToClientStatus) {
      requestToClientStatus === 200
        ? messageSuccess("Обращение успешно создано")
        : messageError("При создании обращения произошла ошибка. Пожалуйста, повторите позже.");
      dispatch(sendRequestToClientFailure(null, 0));
    }
  }, [requestToClientStatus]);
  
  const textAreaLength: number = textArea ? textArea?.length : 0;
  const isDisabled: boolean = !!topicOfAppeal && !!textArea;
  const hasUpload: boolean = uploadedFiles.length >= maxSizeFile;
  
  const modalCancel = (): void => {
    setIsModalOpen(false);
    setUploadedFiles([]);
    form.resetFields();
  };
  
  const modalOk = (): void => {
    const clientData: string | undefined = documentClient?.fields?.uuid;
    const uuids: string[] = uploadedFiles?.map((file: IUploadedFilesProps) => file.uuid) ?? [];
    
    const appealRequest: TypicalRequestToClientType = {
      client: clientData,
      category_code: 302,
      task_content: textArea || null,
      task_topic: topicOfAppeal || null,
      files: uuids,
    };
    
    dispatch(sendTypicalRequestToClient(appealRequest, true));
    modalCancel();
  };
  
  const renderModalHeader = (text: string): JSX.Element => {
    return (
      <div className={css.modalHeader}>
        {text}
      </div>
    );
  };
  
  const handleRemoveFile = async (
    e: React.MouseEvent<HTMLElement>,
    fileRemove: UploadedFilesPropsType
  ): Promise<void> => {
    e.preventDefault();
    
    try {
      const params: URLSearchParams = new URLSearchParams();
      params.append("file_uuid", fileRemove?.uuid);
      
      const response = await deleteUploadFile(params.toString());
      message.success(response.data.message);
      
      setUploadedFiles((prevFiles: UploadedFilesPropsType[]): UploadedFilesPropsType[] => {
        if (prevFiles) {
          const newFiles: UploadedFilesPropsType[] =
            prevFiles.filter((file: UploadedFilesPropsType): boolean => file?.uuid !== fileRemove?.uuid);
          
          return newFiles?.length > 0 ? newFiles : [];
        }
        return [];
      });
    } catch (error) {
      message.error("Файл не удален!");
    }
  };
  
  const uploadedFilesList: JSX.Element[] | null = uploadedFiles?.length
    ? uploadedFiles.map((file: UploadedFilesPropsType) => (
      <div key={file?.uuid} className={css.documentsAction}>
        <div className="flex items-center pl-0.5">
          <AttachFileIcon className="flex-shrink-0" />
          <a className={css.fileName} onClick={() => downloadFile(file?.file_name, downloadUrl(file.uuid))}>
            {file?.file_name}
          </a>
        </div>
        <div className={css.deleteIcon} onClick={(e: React.MouseEvent<HTMLElement>) => handleRemoveFile(e, file)}>
          <DeleteDocument className="flex-shrink-0" />
        </div>
      </div>
    ))
    : null;
  
  const modalFooter: JSX.Element = (
    <div>
      <ButtonCustom
        className={`${css.buttonBack} mr-2`}
        key="cancel"
        size="large"
        type="default"
        text="Отменить"
        onClick={modalCancel}
      />
      <ButtonCustom
        id="buttonOk"
        key="ok"
        size="large"
        text="Подтвердить"
        type="primary"
        className={css.buttonOk}
        disabled={!isDisabled}
        onClick={modalOk}
      />
    </div>
  );
  
  const uploadFile = async (fileList: File[]): Promise<void> => {
    const formData: FormData = new FormData();
    fileList.forEach((file: File) => formData.append("files", file));
    setUploadFilesLoader(true);
    
    try {
      const response = await uploadFiles(formData);
      
      setUploadedFiles((prevData: UploadedFilesPropsType[]): UploadedFilesPropsType[] => {
        const safePrevData: UploadedFilesPropsType[] = prevData || [];
        let newData: UploadedFilesPropsType[] = [...safePrevData];
        let newFileCount: number = safePrevData.length;
        
        response?.data?.forEach((file: UploadedFilesPropsType): void => {
          if (!newData.some((existingFile: UploadedFilesPropsType): boolean => existingFile.uuid === file.uuid)) {
            newData.push(file);
            newFileCount++;
          }
        });
        
        if (newData?.length > maxSizeFile) {
          newData = newData.slice(0, maxSizeFile);
          newFileCount = maxSizeFile;
        }
        
        return newData;
      });
      
      if (response?.status === 200) {
        setUploadFilesLoader(false);
      }
    } catch (error) {
      message.error("Ошибка в загрузке файлов!");
      setUploadFilesLoader(false);
    }
  };
  
  const handleFileChange = (info: UploadChangeParam): boolean => {
    const fileList: UploadFile[] = info.fileList;
    
    if (fileList.length > totalFiles.Total5) {
      fileList.splice(totalFiles.Total5);
    }
    
    const allFilesUploading: boolean = fileList.every(
      ({ status }): boolean => status === "uploading"
    );
    
    if (allFilesUploading) {
      const filterFile: UploadFile<unknown>[] = fileList.filter((file: UploadFile<unknown>): boolean => {
        if (!file) return false;
        
        const size: number = file?.size ? file.size / 1024 / 1024 : FileListing.Empty;
        
        return size < maxSizeFile;
      });
      
      if (filterFile.length < fileList.length) {
        message.error("Объем некоторых файлов или файла не должен превышать 5мб.");
      }
      
      const files: File[] = filterFile.map(({ originFileObj }) => originFileObj!) as File[];
      
      if (files.length > FileListing.Empty) {
        uploadFile(files);
      }
    }
    fileList.length = FileListing.Empty;
    
    return false;
  };
  
  const props: UploadProps = {
    name: "file",
    multiple: true,
    customRequest: (): boolean => true,
    accept: validFileFormat,
    onChange: handleFileChange,
    showUploadList: false
  };
  
  const counterUpload = (fileCount: number): boolean =>
    fileCount >= totalFiles.Total5;
  
  return (
    <Form form={form}>
      <Modal
        width={580}
        title={renderModalHeader("Создание обращения")}
        open={isModalOpen}
        onCancel={modalCancel}
        footer={modalFooter}
      >
        <div className={css.modalText}>
          Задача по выбранной теме обращения будет назначена на ответственного бухгалтера
        </div>
        <Form.Item
          label="Тема обращения"
          name="topicOfAppeal"
          className="mt-0 mb-0"
          rules={[{ required: true }]}
          colon={false}
          layout="vertical"
        >
          <Select
            placeholder="Выберите тему обращения"
            options={appealTopic}
            showSearch
            allowClear
            size="large"
            className="mb-5"
            notFoundContent={<ZeroSearch dataFilter />}
          />
        </Form.Item>
        <div className={css.modalText}>
          Опишите вопрос или задачу от клиента для лучшего понимания бухгалтером.
          Вы так же можете прикрепить файлы к задаче, если это необходимо.
        </div>
        <Form.Item
          label="Комментарий"
          name="textArea"
          className="mb-0"
          rules={[{ required: true }]}
          colon={false}
          layout="vertical"
        >
          <TextArea
            rows={4}
            maxLength={2000}
            className="text-base font-normal leading-5 text-gray-900"
            placeholder="Ваш комментарий"
          />
        </Form.Item>
        <div className="mb-5 text-xs font-normal leading-100 text-gray-700 text-right">
          {textAreaLength}/2000
        </div>
        {!hasUpload && (
          <DraggerForm
            props={props}
            fileCount={uploadedFiles?.length as number}
            counterUpload={counterUpload}
            fileSize={maxSizeFile}
            numberOfFiles={5}
            optionalText="Максимум 5 файлов общим объемом не больше 25 мб."
          />
        )}
        <div className={`${css.fileListing} grid justify-items-start items-center`}>
          {uploadFilesLoader ? (
            <Skeleton
              active
              title={false}
              paragraph={{
                rows: 1,
                width: "100%"
              }}
            />
          ) : (
            uploadedFilesList
          )}
        </div>
      </Modal>
    </Form>
  );
};

export default ModalCreatAppeal;