import React, {
  FC,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import css from "../TasksListing.module.css";
import { Tag } from "antd";
import { CatalogTaskStatusType, taskFastFilterStatus } from "../../../../utils/catalog";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../../../reducers/mainReducer";
import { FilterTasksType } from "app/types";
import { getTaskPageNumber, setTaskListingParams } from "../../../../actions/tasks.actions";
import { AppDispatch } from "../../../../store/store";
import dayjs from "dayjs";
import { filterParamsToArray } from "../../../../utils/filterParamsToArray";

interface ITaskListingFastFiltersProps {
  filter: boolean;
  setParamsData: Dispatch<SetStateAction<FilterTasksType | null>>;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  setFilterStatusLabel: Dispatch<SetStateAction<string[]>>;
  selectedTaskStatuses: string[];
  activeStatus: string[];
  setActiveStatus: Dispatch<SetStateAction<string[]>>;
}

interface IActiveFilterProps {
  deadline_lte?: string,
  priority?: string,
}

const TaskListingFastFilters: FC<ITaskListingFastFiltersProps> = ({
  filter,
  setParamsData,
  setCurrentPage,
  setFilterStatusLabel,
  activeStatus,
  setActiveStatus,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const tasksParams: FilterTasksType | null = useSelector((state: AppStateType) => state.tasks.tasksParams);
  
  const firstPage: number = 1;
  const filterVariant: IActiveFilterProps = {
    deadline_lte: dayjs().format("YYYY-MM-DD"),
    priority: "Высокий",
  };
  const taskFilters: CatalogTaskStatusType[] = [
    { status: "deadline_lte", label: "Срочные" },
    { status: "priority", label: "Высокий приоритет" },
  ];
  
  const initializeActiveFilter = (): string[] => {
    if (!tasksParams) return [];
    
    const priorities: string[] = tasksParams.priority ? ["priority"] : [];
    const deadlines: string[] = tasksParams.deadline_lte ? ["deadline_lte"] : [];
    
    return [...priorities, ...deadlines];
  };
  
  const [activeFilter, setActiveFilter] = useState<string[]>(initializeActiveFilter);
  
  const handleTagClick = (status: keyof IActiveFilterProps): void => {
    const newStatuses: boolean = activeFilter.includes(status);
    
    if (!newStatuses) {
      setCurrentPage(firstPage);
      dispatch(getTaskPageNumber(firstPage));
      
      setActiveFilter((prev: string[]) => {
        const newFilters: string[] = [...prev, status];
        
        setParamsData((prev: FilterTasksType | null): FilterTasksType | null => {
          const updatedParams: FilterTasksType | null = prev
            ? { ...prev, ...(newFilters.length ? { [status]: filterVariant[status] } : {}), page: firstPage }
            : null;
          dispatch(setTaskListingParams(updatedParams));
          
          return updatedParams;
        });
        
        return newFilters;
      });
    }
  };
  
  const handleTagClose = (status: keyof IActiveFilterProps): void => {
    setCurrentPage(firstPage);
    dispatch(getTaskPageNumber(firstPage));
    
    setActiveFilter((prev: string[]) => prev.filter((active: string): boolean => active !== status));
    
    setParamsData((prev: FilterTasksType | null): FilterTasksType | null => {
       if (prev && prev[status] !== null) {
         const updatedParams: FilterTasksType | null = prev
           ? { ...prev, [status]: null, page: firstPage }
           : null;

         dispatch(setTaskListingParams(updatedParams));

         return updatedParams;
       } else {
          return prev;
       }
    });
  };
  
  const handleTagStatusClick = (status: string, label: string): void => {
    const newStatuses: boolean = activeStatus.includes(status);

    if (!newStatuses) {
      setCurrentPage(firstPage);
      dispatch(getTaskPageNumber(firstPage));

      setActiveStatus((prev: string[]) => {
        const newFilters: string[] = [...prev, status];

        setParamsData((prev: FilterTasksType | null): FilterTasksType | null => {
          const paramsStatusFilter: string[] = prev?.status_task
            ? filterParamsToArray(prev?.status_task as string)
            : [];
          const removeFastFilters: string[] = paramsStatusFilter.filter((item: string) => !activeStatus.includes(item));
          const newFilterStatus: string[] = [...newFilters, ...removeFastFilters];
          const updatedParams: FilterTasksType | null = prev
            ? { ...prev, ...(newFilters.length ? { status_task: `[${newFilterStatus}]` } : {}), page: firstPage }
            : null;
          dispatch(setTaskListingParams(updatedParams));

          return updatedParams;
        });

        return newFilters;
      });
      setFilterStatusLabel((prev: string[]) => [...prev, label]);
    }
  };
  
  const handleStatusClose = (status: string, label: string): void => {
    setCurrentPage(firstPage);
    dispatch(getTaskPageNumber(firstPage));
    
    setActiveStatus((prev: string[]) => {
      const newFilters: string[] = prev.filter((active: string): boolean => active !== status);
      
      setParamsData((prev: FilterTasksType | null): FilterTasksType | null => {
        const statusTaskFilters: string[] = prev?.status_task
          ? filterParamsToArray(prev.status_task as string)
          : [];
        const newStatusFilters: string[] = statusTaskFilters.filter((active: string): boolean => active !== status);
        
        if (newStatusFilters.length) {
          const updatedParams: FilterTasksType | null = prev
             ? { ...prev, status_task: `[${newStatusFilters}]`, page: firstPage }
             : null;
          
          dispatch(setTaskListingParams(updatedParams));
          return updatedParams;
        } else {
          const { status_task, ...rest } = prev as FilterTasksType;
          const updatedParams: FilterTasksType | null = prev
            ? { ...rest, page: firstPage }
            : null;

          dispatch(setTaskListingParams(updatedParams));
          return updatedParams;
        }
      });
      
      return newFilters;
    });
    setFilterStatusLabel((prev: string[]) => prev.filter((item: string): boolean => item !== label));
  };
  
  return (
    <div className={`flex ${filter ? "" : "mb-3"}`}>
      {taskFilters.map(({ status, label }) => (
        <Tag
          key={status}
          className={`${css.commonTagStyle} ${activeFilter.includes(status) ? css.activeFilters : css.quickFilters}`}
          closable={activeFilter.includes(status)}
          onClick={() => handleTagClick(status as keyof IActiveFilterProps)}
          onClose={() => handleTagClose(status as keyof IActiveFilterProps)}
        >
          <div className={activeFilter.includes(status) ? "mr-1" : ""}>{label}</div>
        </Tag>
      ))}
      {taskFastFilterStatus.map(({ status, label }) => (
        <Tag
          key={status}
          className={`${css.commonTagStyle} ${activeStatus.includes(status) ? css.activeFilters : css.quickFilters}`}
          closable={activeStatus.includes(status)}
          onClick={() => handleTagStatusClick(status, label)}
          onClose={() => handleStatusClose(status, label)}
        >
          <div className={activeStatus.includes(status) ? "mr-1" : ""}>{label}</div>
        </Tag>
      ))}
    </div>
  );
};

export default TaskListingFastFilters;