import React, { FC, JSX, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import css from "./Performers.module.css";
import {
  Drawer,
  Form,
  Input,
  Select,
  InputNumber,
  notification,
  FormInstance
} from "antd";
import PerformersListing from "./PerformersListing/PerformersListing";
import type { ColumnsType } from "antd/es/table";
import { performersRoles } from "./PerformersListing/mocks";
import { ButtonCustom } from "../ui-kit/ButtonCustom/ButtonCustom";
import {
  UserListingType,
  AlphaListParamsType,
  FilterPerformersType,
  UsersListingFilterType
} from "app/types";
import { fetchUserInfo, getUsersListingData } from "../../actions/account.actions";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store/store";
import { AppStateType } from "../../reducers/mainReducer";
import { performersRole } from "../../utils/performersRole";
import ZeroSearch from "../ClientsList/ZeroSearch/ZeroSearch";
import TagCustom from "../ui-kit/TagCustom/TagCustom";
import { useTableSorter } from "../../utils/handleSorterTable";

interface IPerformersProps {
}

const Performers: FC<IPerformersProps> = (): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [clientsNumberFrom, setClientsNumberFrom] = useState<number | null>(null);
  const [clientsNumberTo, setClientsNumberTo] = useState<number | null>(null);
  const [filterData, setFilterData] = useState<FilterPerformersType | null>(null);
  const [performersListParams, setPerformersListParams] = useState<AlphaListParamsType | null>( {
    page: 1,
    page_size: 10,
  });
  const [totalDocsData, setTotalDocsData] = useState<number>(0);
  const [isLoadingData, setLoadingData] = useState<boolean>(false);
  const [sortValue, setSortValue] = useState<string | null>(null);

  const [form] = Form.useForm();

  const dispatch = useDispatch<AppDispatch>();

  const {
    usersListing,
    usersListingPageSize,
    usersListingCurrentPage,
    usersListingTotalDocs,
  } = useSelector((state: AppStateType) => state.account);

  // Фильтры для таблицы сотрудников
  useEffect(() => {
    setLoadingData(true);

    const params: UsersListingFilterType = {
      page_size: performersListParams?.page_size,
      page: performersListParams?.page,
      name: performersListParams?.search,
      show_additional_fields: true,
      order_by: sortValue,
    };

    dispatch(getUsersListingData(params))
      .finally(() => {
        setLoadingData(false);
        
        if (usersListingTotalDocs > totalDocsData) {
          setTotalDocsData(usersListingTotalDocs);
        }
      });
  }, [performersListParams, sortValue]);

  const filtrationError = (): void => {
    notification.error({
      message: "Ошибка",
      description: "Нет данных для фильтрации"
    });
  };

  const showModal = (): void => {
    setIsModalOpen(true);
  };
  
  const numberOfTasksAndClients = (text: string): JSX.Element => (
    <TagCustom color="customDraft" text={text} />
  );

  const renderPerformersTableLink = (
    text: string,
    record: UserListingType,
    dataType: "name" | "role" | "text",
  ) => {
    const { id, attributes, roles, } = record;

    const fullName: string = [attributes?.last_name, attributes?.first_name, attributes?.second_name]
      .map((name: string[]) => name?.[0])
      .join(" ");

    return (
      <Link
        className={css.tableCell}
        to={`/performers/${id}`}
        state={record}
      >
        {dataType === "name" && fullName}
        {dataType === "role" && performersRole(roles)}
        {dataType === "text" && numberOfTasksAndClients(text)}
      </Link>
    );
  };
  
  const [handleSorter] = useTableSorter(null, setPerformersListParams, setSortValue);
  
  const tableHeaderCell = (sortKey: string) => (
  ) => ({ onClick: (e: React.MouseEvent<HTMLElement>) => handleSorter(e, sortKey), });

  const columns: ColumnsType<UserListingType> = [
    {
      title: "Сотрудник",
      dataIndex: "employee",
      sorter: true,
      showSorterTooltip: false,
      key: "employee",
      width: "52%",
      className: css.tableHeader,
      onHeaderCell: tableHeaderCell("lastName"),
      render: (_, record: UserListingType) => renderPerformersTableLink(_, record, "name"),
    },
    {
      title: "Роль",
      dataIndex: "role",
      sorter: true,
      showSorterTooltip: false,
      key: "role",
      width: "16%",
      className: css.tableHeader,
      onHeaderCell: tableHeaderCell("roles"),
      render: (_, record: UserListingType) => renderPerformersTableLink(_, record, "role"),
    },
    {
      title: "Назначенные клиенты",
      dataIndex: "assigned_clients",
      sorter: true,
      showSorterTooltip: false,
      key: "assigned_clients",
      width: "16%",
      className: css.tableHeader,
      onHeaderCell: tableHeaderCell("amount_of_clients"),
      render: (text: string, record: UserListingType) => renderPerformersTableLink(text, record, "text"),
    },
    {
      title: "Активные задачи",
      dataIndex: "active_tasks",
      sorter: true,
      showSorterTooltip: false,
      key: "active_tasks",
      width: "16%",
      className: css.tableHeader,
      onHeaderCell: tableHeaderCell("amount_of_tasks"),
      render: (text: string, record: UserListingType) => renderPerformersTableLink(text, record, "text"),
    },
  ];

  const modalOk = async (): Promise<any> => {
    try {
      const values = await form.validateFields();
      const {employeeName, role} = values;
      setFilterData({
        name: employeeName || null,
        role: role || null,
        clients_number_from: clientsNumberFrom || null,
        clients_number_to: clientsNumberTo || null,
      });
      setIsModalOpen(false);
      setClientsNumberFrom(null);
      setClientsNumberTo(null);
      form.resetFields();
    } catch {
      filtrationError();
    }
  };

  const modalCancel = (): void => {
    setIsModalOpen(false);
    setClientsNumberFrom(null);
    setClientsNumberTo(null);
    form.resetFields();
  };

  const modalTitle: JSX.Element = (
    <div className={css.modalHeader}>
      Фильтры
    </div>
  );

  const buttonAccess = (): boolean => {
    const values = form.getFieldsValue([
      "employeeName",
      "role"
    ]);

    return Object.values(values).some(value => value !== null && value !== undefined && value !== "")
      || !!clientsNumberFrom
      || !!clientsNumberTo;
  };

  const modalFooter: JSX.Element = (
    <div className={css.modalFooter}>
      <div key="modalBotton" className={css.modalFooterButton}>
        <ButtonCustom
          className={css.buttonStyle}
          key="cancel"
          size="large"
          type="default"
          text="Отменить"
          onClick={modalCancel}
        />
        <ButtonCustom
          className={css.buttonStyle}
          key="ok"
          size="large"
          text="Применить"
          type="primary"
          onClick={modalOk}
          disabled={!buttonAccess()}
        />
      </div>
    </div>
  );

  const onChangeNumberFrom = (value: number | null): void => {
    setClientsNumberFrom(value);
  };

  const onChangeNumberTo = (value: number | null): void => {
    setClientsNumberTo(value);
  };

  const [formData, setFormData] = useState<FormInstance | null>(null);

  useEffect(() => {
    form.validateFields();
  }, [formData]);

  const onValuesChange = (_: unknown, allValues: FormInstance): void => {
    setFormData(allValues);
  };

  return (
    <Form
      form={form}
      onValuesChange={onValuesChange}
    >
      <div className={css.container}>
        <h1 className={`${css.containerHeader} mb-3`}>Исполнители</h1>
        <PerformersListing
          columns={columns}
          showModal={showModal}
          filterData={filterData}
          tableData={usersListing}
          setFilterData={setFilterData}
          usersListingPageSize={usersListingPageSize}
          usersListingCurrentPage={usersListingCurrentPage}
          usersListingTotalDocs={usersListingTotalDocs}
          performersListParams={performersListParams}
          setPerformersListParams={setPerformersListParams}
          isLoadingData={isLoadingData}
          totalDocsData={totalDocsData}
        />
        <Drawer
          title={modalTitle}
          footer={modalFooter}
          onClose={modalCancel}
          open={isModalOpen}
        >
          <div className={css.modalContainer}>
            <div className={`${css.modalText} mb-5`}>
              Отфильтруйте исполнителей по необходимым параметрам
            </div>
            <div className="mb-5">
              <Form.Item name="employeeName" className="my-0">
                <Input
                  id="employeeName"
                  placeholder="ФИО сотрудника"
                  className={`${css.modalInput} mb-4`}
                />
              </Form.Item>
              <Form.Item name="role" className="my-0">
                <Select
                  id="role"
                  placeholder="Роль"
                  options={performersRoles}
                  showSearch
                  allowClear
                  size="large"
                  className={css.modalDrop}
                  notFoundContent={<ZeroSearch dataFilter />}
                />
              </Form.Item>
            </div>
            <div>
              <div className={`${css.modalTextTask} mb-2`}>
                По количеству клиентов
              </div>
              <div className="flex justify-between">
                <InputNumber
                  id="clientsFrom"
                  className={css.clientsNumber}
                  size="large"
                  min={1}
                  precision={0}
                  placeholder="От"
                  value={clientsNumberFrom}
                  onChange={onChangeNumberFrom}
                />
                <InputNumber
                  id="clientsTo"
                  className={css.clientsNumber}
                  size="large"
                  min={1}
                  precision={0}
                  placeholder="До"
                  value={clientsNumberTo}
                  onChange={onChangeNumberTo}
                />
              </div>
            </div>
          </div>
        </Drawer>
      </div>
    </Form>
  );
};

export default Performers;
