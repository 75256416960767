import React, {
  FC,
  JSX,
  Dispatch,
  useState,
  useEffect,
  SetStateAction,
} from "react";
import { ButtonCustom } from "../../ui-kit/ButtonCustom/ButtonCustom";
import { ReactComponent as WarningIcon } from "../../../assets/icons/warning_circle_icon.svg";
import css from "./ClientProfileCardOrganisation.module.css";
import ClientProfileCardImageViewer from "../ClientProfileCardImageViewer/ClientProfileCardImageViewer";
import {
  AbstractDocType,
  AccountsFilesType,
  DocumentClientProfileType,
  DocumentSchemaLabelMapType,
  DocumentTaskType,
  SchemaType,
  UpdateDocumentFieldType
} from "app/types";
import ClientProfileCardTransactionCheckModal
  from "../ClientProfileCardTransactionCheckModal/ClientProfileCardTransactionCheckModal";
import {
  getBankPackagesStatementData,
  getCompanyDocumentData,
  updateCompanyDocumentData,
} from "../../../actions/document.actions";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../store/store";
import { AppStateType } from "../../../reducers/mainReducer";
import { handlePhoneNumberChange } from "../../../utils/phoneNumberMask";
import { TaskQuestionnaireCardSteps } from "../ClientProfileCard";
import { downloadFile } from "../../../utils/downloadFile";
import TagCustom from "../../ui-kit/TagCustom/TagCustom";
import ZeroClientProfileDocument from "../ZeroClientProfileDocument/ZeroClientProfileDocument";
import LoadingCustom from "../../ui-kit/LoadingCustom/LoadingCustom";
import { documentSchemaLabelsMapper } from "../../../utils/documentSchemaLabelsMapper";

interface IClientProfileCardOrganisationProps {
  closeForm: () => void;
  saveCompanyFormData: (docUuid: string, data: UpdateDocumentFieldType | null) => void;
  documentType: string;
  documentUuid: string;
  showImageViewer: (docType: string, uuid: string) => void;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  isImageViewerOpen: boolean;
  schemaClientProfile: SchemaType | null;
  documentClientProfile: DocumentClientProfileType | null;
  isIndividualEntrepreneur: boolean;
  isValidationCompleted: boolean;
}

const ClientProfileCardOrganisation: FC<IClientProfileCardOrganisationProps> = ({
  closeForm,
  saveCompanyFormData,
  documentType,
  documentUuid,
  showImageViewer,
  setCurrentStep,
  isImageViewerOpen,
  schemaClientProfile,
  documentClientProfile,
  isIndividualEntrepreneur,
  isValidationCompleted,
}): JSX.Element => {
  const [isTaxationApproved, setTaxationApproved] = useState<boolean>(true);
  const [isUsnConfirmed, setUsnConfirmed] = useState<boolean>(false);
  const [usnDocDeclineReason, setUsnDocDeclineReason] = useState<string>("");
  const [isStatementOpened, setStatementOpened] = useState<boolean>(false);
  const [isPatentConfirmed, setPatentConfirmed] = useState<boolean>(false);
  const [patentDocDeclineReason, setPatentDocDeclineReason] = useState<string>("");
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const clientProfileOrgLabels: DocumentSchemaLabelMapType = documentSchemaLabelsMapper(schemaClientProfile);

  const dispatch = useDispatch<AppDispatch>();

  const {
    usnCompanyDoc,
    bankCompanyDoc,
    migrationCompanyDoc,
    bankStatementData
  } = useSelector((state: AppStateType) => state.document);

  useEffect(() => {
    if (schemaClientProfile) {
      dispatch(getBankPackagesStatementData(schemaClientProfile?.uuid));
    }
  }, [dispatch, schemaClientProfile]);

  const documentTask: DocumentTaskType | null = useSelector((state: AppStateType) => state.tasks.documentTask);
  const {
    usn_document,
    bank_document,
    patent,
    name,
    registered_address,
    opf,
    inn,
    kpp,
    company_ogrn,
    phone,
    email,
    secondary_okved,
    primary_okved,
    has_unsupported_okved
  } = documentClientProfile?.fields || {};

  const hasNoBankDocFiles: boolean = !!bank_document?.flatMap(({ files }) => files)?.length;
  const noBankDocument: boolean = !bank_document?.length || !!bank_document?.length && hasNoBankDocFiles;

  useEffect(() => {
    const usnDocUuid: string = usn_document?.length
      ? usn_document?.[0]?.uuid
      : "";

    const bankDocUuid: string = bank_document?.length
      ? bank_document?.[0]?.uuid
      : "";

    const patentDocUuid: string = patent?.length
      ? patent?.[0]?.uuid
      : "";

    if (usnDocUuid) {
      dispatch(getCompanyDocumentData("usn-schema", usnDocUuid));
    }

    if (bankDocUuid) {
      dispatch(getCompanyDocumentData("bank-schema", bankDocUuid));
    }

    if (patentDocUuid) {
      dispatch(getCompanyDocumentData("patent-schema", patentDocUuid));
    }
  }, [dispatch, documentClientProfile]);

  useEffect(() => {
    const usnDocStatus: string | null = usnCompanyDoc?.fields?.document_status ?? null;
    const usnDocDeclineReason: string = usnCompanyDoc?.fields?.decline_reason ?? "";
    const bankDocStatus: string | null = bankCompanyDoc?.fields?.document_status ?? null;
    const patentDocStatus: string | null = migrationCompanyDoc?.fields?.document_status ?? null;
    const patentDocDeclineReason: string | null = migrationCompanyDoc?.fields?.decline_reason ?? "";

    setUsnDocDeclineReason(usnDocDeclineReason);
    setPatentDocDeclineReason(patentDocDeclineReason);

    if (usnDocStatus === "DECLINED" || usnDocStatus === null) {
      setUsnConfirmed(false);
    } else {
      setUsnConfirmed(true);
    }

    if (bankDocStatus === "DECLINED" || bankDocStatus === null) {
      setTaxationApproved(false);
    } else {
      setTaxationApproved(true);
    }

    if (patentDocStatus === "DECLINED" || patentDocStatus === null) {
      setPatentConfirmed(false);
    } else {
      setPatentConfirmed(true);
    }
  }, [migrationCompanyDoc, usnCompanyDoc, bankCompanyDoc]);

  const renderConfirmationInfo = (isConfirmed: boolean): JSX.Element => {
    return !isConfirmed ? (
      <TagCustom color="warning" text="Не согласовано"/>
    ) : (
      <TagCustom color="success" text="Согласовано"/>
    );
  };

  const pageChange = (stepChange: number): void => {
    setCurrentStep(stepChange);
    window.scrollTo(0, 0);
  };

  const renderCause = (cause: string): JSX.Element => {
    return (
      <div className={`${css.cause} mb-3`}>
        <div className="inline-block mb-1 gray-color text-sm">
          Причина
        </div>
        <div className="text-base font-normal leading-5">
          {cause}
        </div>
      </div>
    );
  };

  const confirmBankDoc = async (docStatus: string): Promise<void> => {
    setButtonLoading(true);

    const updateDocuments: Promise<any>[] =
      bank_document?.map(async (bankDocument: AbstractDocType): Promise<any> => {
        if (bankCompanyDoc?.fields?.uuid) {
          const updatedBankDoc: UpdateDocumentFieldType = {
            ...bankCompanyDoc,
            uuid: bankCompanyDoc?.fields?.uuid,
            files: bankCompanyDoc?.fields?.files,
            decline_reason: bankCompanyDoc?.fields?.decline_reason,
            document_status: docStatus,
          };

          return dispatch(updateCompanyDocumentData(
            "bank-schema",
            bankDocument.uuid ?? "",
            updatedBankDoc
          ));
        }
        return Promise.resolve();
      }) ?? [];

    await Promise.all(updateDocuments);
    setButtonLoading(false);
  };

  const checkValidated = (array: AbstractDocType[]): boolean => {
    for (const obj of array) {
      if (obj.document_status === "PROCESSING_ERROR") {
        return false;
      }
    }
    return true;
  };

  const bankValidation: boolean = bank_document !== undefined
    ? checkValidated(bank_document) : true;

  const isBankDocVerified: boolean = !!bankCompanyDoc?.fields?.document_status
    && bankCompanyDoc?.fields?.document_status !== "PROCESSING_ERROR";

  const downloadAccounts = async (): Promise<void> => {
    const processed: Set<string> = new Set();

    if (bank_document) {
      for (const entry of bank_document) {
        if (Array.isArray(entry.files) && entry.files.length > 0) {
          const file: AccountsFilesType = entry.files[0];

          const key: string = `${file.label}:${file.url}`;

          if (!processed.has(key)) {
            await downloadFile(file.label, file.url);
            processed.add(key);
          }
        }
      }
    }
  };

  const [loader, setLoader] = useState<boolean>(false);

  useEffect(() => {
    if (documentClientProfile) {
      setLoader(true);
    }
  }, [documentClientProfile]);

  const organizationData: boolean = !!name
    || !!registered_address
    || !!opf
    || !!inn
    || !!kpp
    || !!company_ogrn
    || !!phone
    || !!email;

  const statementData: boolean = !!secondary_okved?.length
    || !!primary_okved
    || (bank_document ? !!bank_document?.length : false);

  const openStatement = (): void => {
    setStatementOpened(true);
  };

  const renderBankDocument = (): JSX.Element => {
    return (noBankDocument ?
      (
        <>
          {!!has_unsupported_okved && (
            <div className={`${css.warningText} mb-5`}>
              <WarningIcon className="flex-shrink-0 mr-1"/>
              <div>
                Присутствует неподдерживаемый ОКВЭД. Проверьте выписку.
              </div>
            </div>
          )}
          {!!bank_document?.length && !bankValidation && (
            <div className={`${css.warningText} mb-5`}>
              <WarningIcon className="flex-shrink-0 mr-1"/>
              <div>
                Файл выписки некорректный, требуется проверить выписку вручную и внести необходимые данные в 1С
              </div>
            </div>
          )}
          {!!has_unsupported_okved && (
            <div className="flex items-center">
              <ButtonCustom
                id="checkStatement"
                className={`${css.button} mr-2`}
                type="primary"
                ghost
                loading={buttonLoading}
                text="Проверить выписку"
                onClick={openStatement}
              />
              <>
                {isBankDocVerified && renderConfirmationInfo(isTaxationApproved)}
              </>
            </div>
          )}
          <ButtonCustom
            id="downloadAccounts"
            className={`${css.button} mt-2`}
            type="primary"
            ghost
            text="Скачать файл выписки"
            onClick={downloadAccounts}
          />
        </>
      ) : <ZeroClientProfileDocument variant="Выписка"/>
    );
  };
  
  const renderItem = (label: string, text: string | JSX.Element[]): JSX.Element => (
    <li className="textBlock">
      <span className="flex-none gray-color field-label">
        {label}
      </span>
      <span className="flex-grow text-sm">
        {Array.isArray(text) ? text : <span>{text}</span>}
      </span>
    </li>
  );

  return (
    <div>
      {loader ? (
        <>
          <div className={`${css.container} p-5 mb-3 bg-white border-bottom-gray`}>
            <h1 className="header-text mb-5">
              Организация
            </h1>
            {organizationData ? (
              <div>
                <ul>
                  {!!name &&
                    renderItem(clientProfileOrgLabels?.["name"]?.label, name)
                  }
                  {!!registered_address &&
                    renderItem(clientProfileOrgLabels?.["registered_address"]?.label, registered_address)
                  }
                  {!!opf &&
                    renderItem(clientProfileOrgLabels?.["opf"]?.label, opf)
                  }
                  {!!inn &&
                    renderItem(clientProfileOrgLabels?.["inn"]?.label, inn)
                  }
                  {!!kpp &&
                    renderItem(clientProfileOrgLabels?.["kpp"]?.label, kpp)
                  }
                  {!!company_ogrn &&
                    renderItem(clientProfileOrgLabels?.["company_ogrn"]?.label, company_ogrn)
                  }
                  {!!phone &&
                    renderItem(clientProfileOrgLabels?.["phone"]?.label, handlePhoneNumberChange(phone))
                  }
                  {!!email &&
                    renderItem(clientProfileOrgLabels?.["email"]?.label, email)
                  }
                </ul>
              </div>
            ) : (
              <ZeroClientProfileDocument variant="Организация"/>
            )}
          </div>
          <div className={`${css.container} p-5 mb-3 bg-white border-bottom-gray`}>
            <h1 className="header-text mb-5">
              Выписка
            </h1>
            {statementData ? (
              <>
                <ul>
                  {!!secondary_okved?.length &&
                    renderItem(
                      clientProfileOrgLabels?.["secondary_okved"]?.label,
                      secondary_okved?.map((okved: string, idx: number) => (
                        <span className="pr-2" key={idx}>{okved}</span>
                      )))
                  }
                  {!!primary_okved &&
                    renderItem(clientProfileOrgLabels?.["primary_okved"]?.label, primary_okved)
                  }
                </ul>
                {renderBankDocument()}
              </>
            ) : (
              <ZeroClientProfileDocument variant="Выписка"/>
            )}
          </div>
          {!!documentTask?.fields?.client_profile_ip?.length && (
            <div className={`${css.container} p-5 mb-3 bg-white border-bottom-gray`}>
              <h1 className="header-text mb-5">
                {clientProfileOrgLabels?.patent?.label}
              </h1>
              {patent?.length ? (
                <div>
                  <div className={css.transitionConfirm}>
                    <div className="flex items-center">
                      <ButtonCustom
                        id="checkPatent"
                        className="w-40 mr-2"
                        type="primary"
                        ghost
                        text="Проверить файлы"
                        onClick={() => showImageViewer(
                          "patent-schema",
                          patent?.[0]?.uuid
                        )
                        }
                      />
                      {!!migrationCompanyDoc?.fields?.document_status && renderConfirmationInfo(isPatentConfirmed)}
                    </div>
                  </div>
                  <div className="mt-3">
                    {!!patentDocDeclineReason && (
                      isPatentConfirmed ? null : renderCause(patentDocDeclineReason)
                    )}
                  </div>
                </div>
              ) : (
                <ZeroClientProfileDocument variant="Патент" />
              )}
            </div>
          )}
          <div className={`${css.container} p-5 mb-3 bg-white border-bottom-gray`}>
            <h1 className="header-text mb-5">
              {clientProfileOrgLabels?.usn_document?.label}
            </h1>
            {usn_document?.length ? (
              <div>
                <div className={css.transitionConfirm}>
                  <div className="flex items-center mt-5">
                    <ButtonCustom
                      id="checkUsn"
                      className="w-40 mr-2" type="primary"
                      ghost
                      text="Проверить файлы"
                      onClick={() => showImageViewer(
                        "usn-schema",
                        usn_document?.[0]?.uuid
                      )}
                    />
                    {!!usnCompanyDoc?.fields?.document_status && renderConfirmationInfo(isUsnConfirmed)}
                  </div>
                </div>
                <div className="mt-3">
                  {!!usnDocDeclineReason && (
                    isUsnConfirmed ? null : renderCause(usnDocDeclineReason)
                  )}
                </div>
              </div>
            ) : (
              <ZeroClientProfileDocument variant="УСН"/>
            )}
          </div>
          <div className={`${css.container} p-5 mb-3 bg-white border-bottom-gray text-end`}>
            <ButtonCustom
              id="stepBack"
              className={`${css.buttonBack} mr-4`}
              disabled
              ghost
              type="primary"
              text="Назад"
              size="large"
            />
            <ButtonCustom
              id="stepForward"
              type="primary"
              className={css.buttonOk}
              text="Продолжить"
              size="large"
              onClick={() =>
                pageChange(!isIndividualEntrepreneur
                  ? TaskQuestionnaireCardSteps.ManagerDetailsStep
                  : TaskQuestionnaireCardSteps.EmployeesStep
                )
              }
            />
          </div>
        </>
      ) : (
        <div className={`flex items-center justify-center ${css.container} h-[70vh] bg-white border-bottom-gray`}>
          <LoadingCustom className="w-full"/>
        </div>
      )}
      {isImageViewerOpen && (
        <ClientProfileCardImageViewer
          closeForm={closeForm}
          saveCompanyFormData={saveCompanyFormData}
          documentType={documentType}
          documentUuid={documentUuid}
          isImageViewerOpen={isImageViewerOpen}
          isValidationCompleted={isValidationCompleted}
          isCompany
        />
      )}
      {isStatementOpened && (
        <ClientProfileCardTransactionCheckModal
          docStatus={bankCompanyDoc?.fields?.document_status ?? ""}
          confirmBankDoc={confirmBankDoc}
          isStatementOpened={isStatementOpened}
          setStatementOpened={setStatementOpened}
          isValidationCompleted={isValidationCompleted}
        />
      )}
    </div>
  );
};

export default ClientProfileCardOrganisation;
