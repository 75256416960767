import React, {
  FC,
  JSX,
  useState,
  useEffect,
  ChangeEvent,
} from "react";
import { TypicalTaskDecisionType } from "app/types";
import { ButtonCustom } from "../../../../../ui-kit/ButtonCustom/ButtonCustom";
import { Form, Input, FormInstance } from "antd";
import { reassignedUserRole } from "../../../../utils/reassignedUserRole";
import { fetchSelectedUser } from "../../../../../../api/account.api";
import css from "../TaskCardActionDrawer.module.css";

interface IReassignTaskProps {
  form: FormInstance,
  taskUuid: string;
  onFinish: (actionBody: TypicalTaskDecisionType, isReassignTask?: boolean) => void;
  onDrawerClose: () => void;
  taskActionComment: string;
  handleTextAreaChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  remainingCharsComment: number;
  responsibleReferenceUuid: string;
}

const isReassignTask: boolean = true;

const { TextArea } = Input;

const ReassignTask: FC<IReassignTaskProps> = ({
  form,
  taskUuid,
  onFinish,
  onDrawerClose,
  taskActionComment,
  handleTextAreaChange,
  remainingCharsComment,
  responsibleReferenceUuid,
}): JSX.Element => {
  const [responsibleRoles, setResponsibleRoles] = useState<string[]>([]);

  const reassignTaskBody: TypicalTaskDecisionType = {
    task: taskUuid,
    task_decision_type: "reassign_task",
    comment: taskActionComment,
  };

  useEffect(() => {
    const getUserInfo = async (): Promise<void> => {
      try{
        const response: any = await fetchSelectedUser(responsibleReferenceUuid);
        setResponsibleRoles(response?.data?.roles);
      } catch (err) {
        console.error("Get user info error:", err);
      }
    };

    if (responsibleReferenceUuid) {
      getUserInfo();
    }
  }, [responsibleReferenceUuid]);

  const onReassignTaskFinish = (): void => {
    onFinish(reassignTaskBody, isReassignTask);
  };

  return (
    <>
      <p className={css.actionDrawerDescr}>
        Вы можете переназначить это обращение
        {reassignedUserRole(responsibleRoles)}
        и по желанию оставить комментарий
      </p>
      <Form
        className="m-0"
        name="reassignTask"
        form={form}
        onFinish={onReassignTaskFinish}
      >
        <Form.Item
          className="mb-4"
          name="reassignComment"
        >
          <TextArea
            id="reassignComment"
            rows={25}
            maxLength={2000}
            className="text-base font-normal leading-5 text-gray-900"
            placeholder="Ваш комментарий"
            onChange={handleTextAreaChange}
          />
          <div className="text-xs font-normal leading-100 counter-gray-color text-right">
            {remainingCharsComment}/2000
          </div>
        </Form.Item>
        <Form.Item className="text-right">
          <ButtonCustom
            className="mr-2"
            size="large"
            type="default"
            ghost
            text="Отменить"
            onClick={onDrawerClose}
          />
          <ButtonCustom
            size="large"
            type="primary"
            text="Подтвердить"
            htmlType="submit"
          />
        </Form.Item>
      </Form>
    </>
  );
};

export default ReassignTask;
