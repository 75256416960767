import React, { FC, JSX, useEffect, useState } from "react";
import { NavigateFunction, useLocation, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store/store";
import { logout } from "../../actions/account.actions";
import { ReactComponent as LogoutIcon } from "../../assets/icons/logout_v2.svg";
import { ReactComponent as ClientsIcon } from "../../assets/icons/users_group_icon.svg";
import { ReactComponent as TaskIcon } from "../../assets/icons/new_tasks_icon.svg";
import { ReactComponent as ProfileIcon } from "../../assets/icons/profile_v2.svg";
import { ReactComponent as ReportsIcon } from "../../assets/icons/library.svg";
import { ReactComponent as NotificationIcon } from "../../assets/icons/notification_v2.svg";
import { ReactComponent as NavbarIcon } from "../../assets/icons/navbar_icon.svg";
import { ReactComponent as ArrowLeftIcon } from "../../assets/icons/arrow_left_v2.svg";
import { ReactComponent as ArrowRightIcon } from "../../assets/icons/arrow_right_v2.svg";
import { ReactComponent as PerformersIcon} from "../../assets/icons/user_check.svg";
import { ReactComponent as VacancyIcon} from "../../assets/icons/documents-minimalistic.svg";
import { ReactComponent as ContractsIcon} from "../../assets/icons/contracts.svg";
import type { MenuProps } from "antd";
import { Button, Menu } from "antd";
import Sider from "antd/es/layout/Sider";
import css from "./NavBar.module.css";
import Notifications from "../Notifications/Notifications";
import NotificationsCounter from "../Notifications/NotificationsCounter/NotificationsCounter";
import * as jose from "jose";
import { AppStateType } from "../../reducers/mainReducer";
import {
  getTaskPageNumber,
  getTaskPageSize,
  setTaskListingFilter,
  setTaskListingLoadingDefault,
  setTaskListingParams,
  setTaskListingSorter,
  setTaskListingTagKey
} from "../../actions/tasks.actions";
import {
  getClientBanksPageNumber,
  getClientBanksPageSize,
  getClientBanksSearch,
  getClientBanksSorter,
  getClientsPageNumber,
  getClientsPageSize,
  getCommentsDataFailure,
  setClientCardTagKey,
  setClientsListingFilter,
  setClientsListingLoadingDefault,
  setClientsListingParams
} from "../../actions/client.actions";
import {
  getEmployeesPageNumber,
  getEmployeesPageSize,
  setClientsEmployeeListingLoadingDefault,
  setClientsEmployeesListingFilter,
  setClientsEmployeesListingParamsData
} from "../../actions/employee.actions";
import {
  getReportsPageNumber,
  getReportsPageSize,
  setReportsListingParamsData
} from "../../actions/reports.action";
import { roleResolver } from "../../utils/roleResolver";

type MenuItem = Required<MenuProps>["items"][number];

interface INavBarProps {
  setNavBarHidden: React.Dispatch<React.SetStateAction<boolean>>;
  collapsed: boolean;
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IJWTPayloadProps extends jose.JWTPayload {
  resource_access?: {
    bc: {
      roles: string[];
    }
  }
}

enum PaginationDefault {
  defaultPage = 1,
  defaultSize = 10
}

const NavBar: FC<INavBarProps> = ({
  setNavBarHidden,
  collapsed,
  setCollapsed
}): JSX.Element => {
  const pathname: string = useLocation()?.pathname;

  const [selectedItem, setSelectedItem] = useState<string[]>([pathname?.split("/")[1]]);
  const [notificationVisible, setNotificationVisible] = useState<boolean>(false);
  const [isHovering, setIsHovering] = useState<boolean>(false);

  const decodedToken: IJWTPayloadProps | null = useSelector((state: AppStateType) => state.account.decodedToken);

  useEffect(() => {
    selectCurrentItem();
  }, [pathname]);

  const setActiveMenuItem = (itemName: string): void => {
    if (pathname?.split("/")?.includes(itemName)) setSelectedItem([itemName]);
  };

  const selectCurrentItem = () => {
    const pathWords: string[] = pathname?.split("/") || [];
    const hasTask: boolean = pathWords.includes("task");
    const hasClients: boolean = pathWords.includes("clients");
    
    if (hasTask && hasClients) {
      setSelectedItem(["clients"]);
    }
    else if (hasTask) {
      setSelectedItem(["tasks"]);
    }
    else {
      setActiveMenuItem("clients");
      setActiveMenuItem("profile");
      setActiveMenuItem("reports");
      setActiveMenuItem("performers");
      setActiveMenuItem("alpha/contracts");
      setActiveMenuItem("alpha/vacancies");
      setActiveMenuItem("alpha/contractors");
      setActiveMenuItem("alpha/my_company");
    }
  };

  const dispatch = useDispatch<AppDispatch>();

  const navigate: NavigateFunction = useNavigate();

  const getMenuItem = (
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: "group"
  ): MenuItem => {
    return {
      key,
      icon,
      children,
      label,
      type,
    } as MenuItem;
  };

  const logOut = (): void => {
    setNavBarHidden(true);
    dispatch(logout());

    // Сбрасываем фильтры листингов
    taskListingDefaultValue();
    clientListingDefaultValue();
    reportsListingDefaultValue();
  };

  const isAdmin: boolean = roleResolver(decodedToken).isAdmin;
  const isAlphaAdmin: boolean = roleResolver(decodedToken).isAlphaAdmin;
  const isQualityControl: boolean = roleResolver(decodedToken).isQualityControl;
  const isAlphaContractor: boolean = roleResolver(decodedToken).isAlphaContractor;
  const isTechSupport: boolean = roleResolver(decodedToken).isTechSupport;
  const isAlphaUser: boolean = isAlphaAdmin || isAlphaContractor;

  const publicItems: MenuItem[] = [
    getMenuItem(
      <Link
        to="/tasks"
        className={css.menuText}
      >
        Задачи
      </Link>,
      "tasks",
      <TaskIcon className="flex-shrink-0"/>
    ),
    getMenuItem(
      <Link
        to="/clients"
        className={css.menuText}>
        Клиенты
      </Link>,
      "clients",
      <ClientsIcon className="flex-shrink-0"/>
    ),
    // Скрываем этот раздел в меню до начала его реализации, в MVP его не планируется
    // getMenuItem(
    //   <Link
    //   to="/appeals"
    //   className={`${selectedItem[0] === "appeals" ? "font-bold" : ""}`}
    //   >
    //     Обращения
    //   </Link>,
    //   "appeals",
    //   selectedItem[0] === "appeals" ? <MessagesSelectedIcon /> : <MessagesIcon />
    // ),
    (isQualityControl || isTechSupport) ? getMenuItem(
      <Link
        to="/reports"
        className={css.menuText}
      >
        Отчеты
      </Link>,
      "reports",
      <ReportsIcon className="flex-shrink-0"/>
    ) : null,
    (isAdmin || isTechSupport) ? getMenuItem(
      <Link
        to="/performers"
        className={css.menuText}
      >
        Исполнители
      </Link>,
      "performers",
      <PerformersIcon className="flex-shrink-0"/>
    ) : null,
  ];

  const alphaMenuItems: MenuItem[] = [
    getMenuItem(
      <Link
        to="/alpha/vacancies"
        className={css.menuText}
      >
        Вакансии
      </Link>,
      "/alpha/vacancies",
      <VacancyIcon className="flex-shrink-0"/>
    ),
    isAlphaAdmin ? getMenuItem(
      <Link
        to="/alpha/applications"
        className={css.menuText}
      >
        Заявки
      </Link>,
      "/alpha/applications",
      <TaskIcon className="flex-shrink-0"/>
    ) : null,
    isAlphaAdmin ? getMenuItem(
      <Link
        to="/alpha/contractors"
        className={css.menuText}
      >
        Контрагенты
      </Link>,
      "/alpha/contractors",
      <ClientsIcon className="flex-shrink-0"/>
    ) : null,
    isAlphaAdmin ? getMenuItem(
      <Link
        to="/alpha/contracts"
        className={css.menuText}>
        Договоры
      </Link>,
      "/alpha/contracts",
      <ContractsIcon className="flex-shrink-0"/>
    ) : null,
    isAlphaContractor ? getMenuItem(
      <Link
        to="/alpha/resumes"
        className={css.menuText}>
        Резюме
      </Link>,
      "/alpha/resumes",
      <ProfileIcon className="flex-shrink-0"/>
    ) : null,
  ];

  const personalItems: MenuItem[] = [
    getMenuItem(
      <span className={css.menuText}>
        Уведомления
      </span>,
      "notifications",
      selectedItem[0] === "notifications" ? (
        <div>
          <NotificationIcon className="flex-shrink-0"/>
          <NotificationsCounter collapsed={collapsed}/>
        </div>
        ) : (
        <div>
          <NotificationIcon className="flex-shrink-0"/>
          <NotificationsCounter collapsed={collapsed}/>
        </div>
        )
    ),
    !isAlphaAdmin ? getMenuItem(
      <Link
        to={isAlphaContractor ? "/alpha/my_company" : "/profile"}
        className={css.menuText}
      >
        {isAlphaContractor ? "Моя компания" : "Мой профиль"}
      </Link>,
      isAlphaContractor ? "alpha/my_company" : "profile",
      <ProfileIcon className="flex-shrink-0"/>
    ) : null,
    getMenuItem(
      <span
        className={css.menuText}
        onClick={() => logOut()}
      >
        Выйти
      </span>,
      "logout",
      <LogoutIcon className="flex-shrink-0"/>
    )
  ];

  const toggleCollapsed = (): void => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    notificationVisible
      ? setSelectedItem(["notifications"])
      : selectCurrentItem();
  }, [notificationVisible]);

  const taskListingDefaultValue = (): void => {
    dispatch(getTaskPageSize(PaginationDefault.defaultSize));
    dispatch(getTaskPageNumber(PaginationDefault.defaultPage));
    dispatch(setTaskListingParams(null));
    dispatch(setTaskListingFilter(null));
    dispatch(setTaskListingSorter("-number"));
    dispatch(setTaskListingTagKey("1"));
    dispatch(setTaskListingLoadingDefault(true));
  };

  const clientListingDefaultValue = (): void => {
    dispatch(setTaskListingLoadingDefault(true));
    dispatch(getTaskPageNumber(PaginationDefault.defaultPage));
    dispatch(getTaskPageSize(PaginationDefault.defaultSize));
    dispatch(setTaskListingParams(null));
    dispatch(setTaskListingSorter("-number"));
    dispatch(setTaskListingTagKey("1"));

    dispatch(setClientsListingParams(null));
    dispatch(setClientsListingFilter(null));
    dispatch(getClientsPageNumber(PaginationDefault.defaultPage));
    dispatch(getClientsPageSize(PaginationDefault.defaultSize));
    dispatch(setClientCardTagKey("1"));
    dispatch(setClientsListingLoadingDefault(true));

    dispatch(setClientsEmployeesListingParamsData(null));
    dispatch(getEmployeesPageSize(PaginationDefault.defaultSize));
    dispatch(getEmployeesPageNumber(PaginationDefault.defaultPage));
    dispatch(setClientsEmployeesListingFilter(null));
    dispatch(setClientsEmployeeListingLoadingDefault(true));

    dispatch(getClientBanksPageNumber(PaginationDefault.defaultPage));
    dispatch(getClientBanksPageSize(PaginationDefault.defaultSize));
    dispatch(getClientBanksSorter("-name"));
    dispatch(getClientBanksSearch(""));
    dispatch(getCommentsDataFailure());
  };

  const reportsListingDefaultValue = (): void => {
    dispatch(getReportsPageNumber(PaginationDefault.defaultPage));
    dispatch(getReportsPageSize(PaginationDefault.defaultSize));
    dispatch(setReportsListingParamsData(null));
  };

  const handleMenuItemClick = ({ key }: { key: string }): void => {
    if (key === "notifications") {
      setNotificationVisible(prev => !prev);
    } else if (key !== "logout") {
      if (key !== "clients") {
        clientListingDefaultValue();
      }

      if (key !== "tasks") {
        taskListingDefaultValue();
      }

      if (key !== "reports") {
        reportsListingDefaultValue();
      }

      navigate(key);
      setNotificationVisible(false);
    } else {
      logOut();
      navigate("/login");
      setNotificationVisible(false);
    }
    setSelectedItem([key]);
  };

  const handleMouseEnter = (): void => {
    setIsHovering(true);
  };

  const handleMouseLeave = (): void => {
    setIsHovering(false);
  };

  const IconComponent = (): JSX.Element => {
    if (isHovering) {
      return collapsed ? <ArrowRightIcon /> : <ArrowLeftIcon />;
    }
    return <NavbarIcon />;
  };

  return (
    <div className="flex">
      <Sider
        style={{
          backgroundColor: "#101928",
          zIndex: "9",
          ...(collapsed ? {} : { paddingLeft: "12px", paddingRight: "12px" }),
        }}
        className="h-100vh border-r-gray bg-black"
        trigger={null}
        collapsible
        collapsed={collapsed}
        collapsedWidth={68}
      >
        <Menu
          mode="inline"
          theme="dark"
          className={`${css.menuHeight} mt-2`}
          selectedKeys={selectedItem}
          onClick={handleMenuItemClick}
          items={!isAlphaUser ? publicItems : alphaMenuItems}
        />
        <Button
          className={`absolute ${collapsed ? "left-2rem" : "right-10rem"} top-50 z-10 menu-btn-black box-shadow-none`}
          type="text"
          onClick={toggleCollapsed}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {IconComponent()}
        </Button>
        <Menu
          mode="inline"
          theme="dark"
          className={`${css.menuDown} mt-2`}
          selectedKeys={selectedItem}
          onClick={handleMenuItemClick}
          items={personalItems}
        />
      </Sider>
      <Notifications
        collapsed={collapsed}
        notificationVisible={notificationVisible}
        setNotificationVisible={setNotificationVisible}
        clientListingDefaultValue={clientListingDefaultValue}
      />
    </div>
  );
};

export default NavBar;
