import {
  CLIENT_BANKS_DATA_PAGE_NUMBER,
  CLIENT_BANKS_DATA_PAGE_SIZE,
  CLIENT_BANKS_DATA_SEARCH,
  CLIENT_BANKS_DATA_SORTER,
  CLIENT_CARD_TAG_KEY,
  CLIENT_LISTING_FILTER_DATA,
  CLIENT_LISTING_LOADING_DEFAULT,
  CLIENT_LISTING_PARAMS_DATA,
  CREAT_CLIENT_LABOUR_REGULATION_FAILURE,
  CREAT_CLIENT_LABOUR_REGULATION_SUCCESS,
  GET_CLIENT_BANKS_DATA_FAILURE,
  GET_CLIENT_BANKS_DATA_SUCCESS,
  GET_CLIENT_COMMENTS_DATA_FAILURE,
  GET_CLIENT_COMMENTS_DATA_SUCCESS,
  GET_CLIENT_LISTING_FAILURE,
  GET_CLIENT_LISTING_SUCCESS,
  GET_CLIENT_TARIFF_FAILURE,
  GET_CLIENT_TARIFF_SUCCESS,
  GET_CLIENTS_PAGE_NUMBER,
  GET_CLIENTS_PAGE_SIZE,
  GET_DOCUMENT_CLIENT_FAILURE,
  GET_DOCUMENT_CLIENT_SUCCESS,
  GET_SCHEMA_CLIENT_FAILURE,
  GET_SCHEMA_CLIENT_SUCCESS,
  GET_SCHEMA_LABOUR_REGULATION_FAILURE,
  GET_SCHEMA_LABOUR_REGULATION_SUCCESS,
  SEND_CLIENT_REQUEST_FAILURE,
  SEND_CLIENT_REQUEST_SUCCESS,
  SET_POPOVER_TARIFF_SWITCH
} from "../constants/actionTypes";
import { ClientActionsTypes } from "../actions/client.actions";
import {
  ClientBanksDataItemsType,
  ClientLabourRegulationType,
  ClientsListingDataType,
  ClientTariffDataType,
  CommentDataType,
  DocumentClientType,
  FilterClientsType,
  SchemaLabourRegulationType,
  SchemaType,
  TypicalRequestToClientResponseType
} from "app/types";

type InitialClientType = {
  error: any;
  schemaClient: null | SchemaType;
  clientsData: null | ClientsListingDataType[];
  clientsPageSize: number;
  clientsPage: number;
  clientsFilter: FilterClientsType | null;
  clientsParams: FilterClientsType | null;
  clientTagKey: string;
  totalDocs: number | null;
  documentClient: null | DocumentClientType;
  isSchemaClientLoading: boolean;
  isListingLoading: boolean;
  isDocumentClientLoading: boolean;
  clientTariffData: null | ClientTariffDataType;
  isClientInfoLoading: boolean;
  clientBanksData: null | ClientBanksDataItemsType[];
  banksPageNumber: number;
  banksPageSize: number;
  banksSorter: string;
  banksSearch: string;
  requestToClient: null | TypicalRequestToClientResponseType;
  requestToClientStatus: number;
  schemaLabour: null | SchemaLabourRegulationType;
  clientLabourRegulation: null | ClientLabourRegulationType;
  clientCommentsData: CommentDataType[];
  showClientTariff: boolean;
};

const initialState: InitialClientType = {
  error: null,
  schemaClient: null,
  clientsData: null,
  clientsPageSize: 10,
  clientsPage: 1,
  clientsFilter: null,
  clientsParams: null,
  clientTagKey: "1",
  totalDocs: null,
  documentClient: null,
  isSchemaClientLoading: true,
  isListingLoading: true,
  isDocumentClientLoading: true,
  clientTariffData: null,
  isClientInfoLoading: true,
  clientBanksData: null,
  banksPageNumber: 1,
  banksPageSize: 10,
  banksSorter: "-name",
  banksSearch: "",
  requestToClient: null,
  requestToClientStatus: 0,
  schemaLabour: null,
  clientLabourRegulation: null,
  clientCommentsData: [],
  showClientTariff: false,
};

const client = (
  state: InitialClientType = initialState,
  action: ClientActionsTypes
): InitialClientType => {
  switch (action.type) {
    // Логика получения схемы клиента
    case GET_SCHEMA_CLIENT_SUCCESS:
      return {
        ...state,
        schemaClient: action.payload.schemaProfile,
        isSchemaClientLoading: false,
        error: null,
      };
    case GET_SCHEMA_CLIENT_FAILURE:
      return {
        ...state,
        schemaClient: null,
        isSchemaClientLoading: false,
        error: action.payload.error,
      };
    //Логика получения листинга клиентов
    case GET_CLIENT_LISTING_SUCCESS:
      return {
        ...state,
        clientsData: action.payload.clientsListing.results,
        totalDocs: action.payload.clientsListing.total_docs,
        error: null,
        isListingLoading: false,
      };
    case GET_CLIENT_LISTING_FAILURE:
      return {
        ...state,
        clientsData: null,
        totalDocs: null,
        error: action.payload.error,
        isListingLoading: false,
      };
    case GET_CLIENTS_PAGE_SIZE:
      return {
        ...state,
        clientsPageSize: action.payload.size,
      };
    case GET_CLIENTS_PAGE_NUMBER:
      return {
        ...state,
        clientsPage: action.payload.page,
      };
    case CLIENT_LISTING_FILTER_DATA:
      return {
        ...state,
        clientsFilter: action.payload.clientsFilter,
      };
    case CLIENT_LISTING_PARAMS_DATA:
      return {
        ...state,
        clientsParams: action.payload.clientsParams,
      };
    case CLIENT_LISTING_LOADING_DEFAULT:
      return {
        ...state,
        isListingLoading: action.payload.loadingDefault,
      };
    case CLIENT_CARD_TAG_KEY:
      return {
        ...state,
        clientTagKey: action.payload.clientTagKey,
      };
    // Логика получения данных схемы клиента по uuid документа
    case GET_DOCUMENT_CLIENT_SUCCESS:
      return {
        ...state,
        documentClient: action.payload.documentClient,
        isDocumentClientLoading: false,
        error: null,
      };
    case GET_DOCUMENT_CLIENT_FAILURE:
      return {
        ...state,
        documentClient: null,
        isDocumentClientLoading: false,
        error: action.payload.error,
      };
    // Логика получения данных тарифа клиента по uuid
    case GET_CLIENT_TARIFF_SUCCESS:
      return {
        ...state,
        clientTariffData: action.payload.clientTariffData,
        error: null,
      };
    case GET_CLIENT_TARIFF_FAILURE:
      return {
        ...state,
        clientTariffData: null,
        error: action.payload.error,
      };
    //Логика получения информации о банках клиента
    case GET_CLIENT_BANKS_DATA_SUCCESS:
      return {
        ...state,
        clientBanksData: action.payload.clientBanksData.items,
        isClientInfoLoading: false,
        error: null,
      };
    case GET_CLIENT_BANKS_DATA_FAILURE:
      return {
        ...state,
        clientBanksData: null,
        isClientInfoLoading: false,
        error: action.payload.error,
      };
    case CLIENT_BANKS_DATA_PAGE_NUMBER:
      return {
        ...state,
        banksPageNumber: action.payload.page,
      };
    case CLIENT_BANKS_DATA_PAGE_SIZE:
      return {
        ...state,
        banksPageSize: action.payload.size,
      };
    case CLIENT_BANKS_DATA_SORTER:
      return {
        ...state,
        banksSorter: action.payload.bankSorter,
      };
    case CLIENT_BANKS_DATA_SEARCH:
      return {
        ...state,
        banksSearch: action.payload.bankSearch,
      };
    //логика получения ответа при отправке Обращения к клиенту
    case SEND_CLIENT_REQUEST_SUCCESS:
      return {
        ...state,
        requestToClient: action.payload.requestToClient,
        requestToClientStatus: action.payload.requestToClientStatus,
        error: null,
      };
    case SEND_CLIENT_REQUEST_FAILURE:
      return {
        ...state,
        requestToClient: null,
        requestToClientStatus: action.payload.requestToClientStatus,
        error: action.payload.error,
      };
    //Логика получения схемы "Положения об оплате труда" клиента
    case GET_SCHEMA_LABOUR_REGULATION_SUCCESS:
      return {
        ...state,
        schemaLabour: action.payload.schemaLabour,
        error: null,
      };
    case GET_SCHEMA_LABOUR_REGULATION_FAILURE:
      return {
        ...state,
        schemaLabour: null,
        error: action.payload.error,
      };
    //Логика получения сущности "Положения об оплате труда" клиента
    case CREAT_CLIENT_LABOUR_REGULATION_SUCCESS:
      return {
        ...state,
        clientLabourRegulation: action.payload.clientLabourRegulation,
        error: null
      };
    case CREAT_CLIENT_LABOUR_REGULATION_FAILURE:
      return {
        ...state,
        clientLabourRegulation: null,
        error: action.payload.error,
      };
    //логика получения комментариев клиента
    case GET_CLIENT_COMMENTS_DATA_SUCCESS:
      return {
        ...state,
        clientCommentsData: action.payload.clientsComments.results,
        error: null,
      };
    case GET_CLIENT_COMMENTS_DATA_FAILURE:
      return {
        ...state,
        clientCommentsData: [],
        error: action.payload.error,
      };
    //логика отображения popover в модальном окне Создать исходящую задачу
    case SET_POPOVER_TARIFF_SWITCH:
      return {
        ...state,
        showClientTariff: action.payload.showClientTariff,
      };
    default:
      return state;
  }
};

export default client;
