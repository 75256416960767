import React, {
  FC,
  JSX,
  useEffect,
  useState
} from "react";
import { useSelector } from "react-redux";
import { AppStateType } from "../../../../../../../reducers/mainReducer";
import { deleteUploadFile, uploadFiles } from "../../../../../../../api/document.api";
import {
  Form,
  message,
  Skeleton,
  UploadFile,
  UploadProps
} from "antd";
import { validFileFormat } from "../../../../../../../utils/ModalUploadItems/validFileFormat";
import { UploadChangeParam } from "antd/es/upload";
import { ReactComponent as AttachFileIcon } from "../../../../../../../assets/icons/attach_file_icon.svg";
import { ReactComponent as DeleteDocument } from "../../../../../../../assets/icons/delete_file.svg";
import { ReactComponent as WarningIcon } from "../../../../../../../assets/icons/warning_blue_icon.svg";
import css from "./RecognizeKnap.module.css";
import {
  ActionHistoryFileType,
  UploadedFilesPropsType,
  TypicalTaskDecisionType,
  ActionHistoryResultsType,
  ActionHistoryType
} from "app/types";
import { downloadFile, downloadUrl } from "../../../../../../../utils/downloadFile";
import DraggerForm from "../../../../../../../utils/ModalUploadItems/DraggerForm/DraggerForm";
import { ButtonCustom } from "../../../../../../ui-kit/ButtonCustom/ButtonCustom";

interface IRecognizeKnapProps {
  taskUuid: string;
  onFinish: (actionBody: TypicalTaskDecisionType) => void;
  onDrawerClose: () => void;
}

interface IFilesProps {
  label: string;
  uuid: string;
}

interface ITasksFilesProps {
  fields: {
    files?: IFilesProps[];
    main_files?: IFilesProps[];
  };
}

enum totalFiles {
  Total100 = 100
}

enum FileListing {
  Empty = 0
}

const maxSizeFile: number = 100;

const RecognizeKnap: FC<IRecognizeKnapProps> = ({ taskUuid, onFinish, onDrawerClose }): JSX.Element => {
  const { documentTask, actionHistory } = useSelector((state: AppStateType) => state.tasks);

  const [uploadedFilesKNAP, setUploadedFilesKNAP] = useState<UploadedFilesPropsType[]>([]);
  const [uploadFilesLoaderKNAP, setUploadFilesLoaderKNAP] = useState<boolean>(false);

  const showUploadKNAP: boolean = uploadedFilesKNAP?.length >= maxSizeFile;
  
  const findCorrectMessage = (actionHistory: ActionHistoryType | null): ActionHistoryResultsType | null => {
    if (!actionHistory) {
      return null;
    }
    
    const decisionList: string[] = ["change_responsible", "change_task_topic", "reassign_task"];
    const [lastHistoryMessage, ...otherHistoryMessage] = actionHistory.results;
    const isExceptionType: boolean = decisionList.includes(lastHistoryMessage?.fields?.task_decision_type);
    
    if (isExceptionType) {
      const foundEntity: ActionHistoryResultsType | undefined =
        otherHistoryMessage.find((entity: ActionHistoryResultsType) =>
          !decisionList.includes(entity?.fields?.task_decision_type)
        );
      
      return foundEntity ?? lastHistoryMessage;
    }
    
    return lastHistoryMessage;
  };

  useEffect(() => {
    const compileFiles = (source: ITasksFilesProps): UploadedFilesPropsType[] => {
      return (
        source?.fields?.files
          ?.concat(source?.fields?.main_files || [])
          .filter((file: IFilesProps) => file.label && file.uuid)
          .map(
            ({ label, uuid }): UploadedFilesPropsType => ({
              file_name: label,
              uuid: uuid
            })
          ) || []
      );
    };

    const documentTaskFiles: UploadedFilesPropsType[] = compileFiles(documentTask as ITasksFilesProps);
    const filesInCorrectActionHistory: ActionHistoryResultsType | null = findCorrectMessage(actionHistory);
    const actionHistoryFiles: UploadedFilesPropsType[] =
      compileFiles(filesInCorrectActionHistory as ITasksFilesProps);
    
    const allFiles: UploadedFilesPropsType[] = actionHistoryFiles.length
      ? actionHistoryFiles
      : documentTaskFiles;

    setUploadedFilesKNAP(allFiles);
  }, [documentTask, actionHistory]);

  const uploadFileKNAP = async (fileList: File[]): Promise<void> => {
    const formData: FormData = new FormData();
    fileList.forEach((file: File) => formData.append("files", file));
    formData.append("upload_mode", "knap");
    setUploadFilesLoaderKNAP(true);

    try {
      const response = await uploadFiles(formData);

      setUploadedFilesKNAP((prevData: UploadedFilesPropsType[] | null) => {
        const safePrevData: UploadedFilesPropsType[] = prevData || [];
        let newData: UploadedFilesPropsType[] = [...safePrevData];
        let newFileCount: number = safePrevData.length;

        response?.data?.forEach((file: UploadedFilesPropsType): void => {
          if (!newData.some((existingFile: UploadedFilesPropsType): boolean => existingFile.uuid === file.uuid)) {
            newData.push(file);
            newFileCount++;
          }
        });

        if (newData?.length > maxSizeFile) {
          newData = newData.slice(0, maxSizeFile);
          newFileCount = maxSizeFile;
        }

        return newData;
      });

      if (response?.status === 200) {
        setUploadFilesLoaderKNAP(false);
      }
    } catch (error) {
      message.error("Ошибка в загрузке файлов!");
      setUploadFilesLoaderKNAP(false);
    }
  };
  
  const handleFileChange = (info: UploadChangeParam): boolean => {
    const fileList: UploadFile[] = info.fileList;
    
    if (fileList.length > totalFiles.Total100) {
      fileList.splice(totalFiles.Total100);
    }
    
    const allFilesUploading: boolean = fileList.every(
      ({ status }): boolean => status === "uploading"
    );
    
    if (allFilesUploading) {
      const filterFile: UploadFile<unknown>[] = fileList.filter((file: UploadFile<unknown>): boolean => {
        if (!file) return false;
        
        const size: number = file?.size ? file.size / 1024 / 1024 : FileListing.Empty;
        
        return size < maxSizeFile;
      });
      
      if (filterFile.length < fileList.length) {
        message.error("Объем некоторых файлов или файла не должен превышать 100мб.");
      }
      
      const files: File[] = filterFile.map(({ originFileObj }) => originFileObj!) as File[];
      
      if (files.length > FileListing.Empty) {
        uploadFileKNAP(files);
      }
    }
    fileList.length = FileListing.Empty;
    
    return false;
  };

  const propsKNAP: UploadProps = {
    name: "file",
    multiple: true,
    customRequest: (): boolean => true,
    accept: validFileFormat,
    onChange: handleFileChange,
    showUploadList: false
  };

  const counterUploadKNAP = (fileCountKNAP: number): boolean =>
    fileCountKNAP >= totalFiles.Total100;

  const removeFileNAPLocale = (fileRemove: UploadedFilesPropsType) => {
    setUploadedFilesKNAP((prevFiles: UploadedFilesPropsType[]): UploadedFilesPropsType[] => {
      if (prevFiles) {
        const newFiles: UploadedFilesPropsType[] = prevFiles.filter(
          ({ uuid }): boolean => uuid !== fileRemove.uuid
        );
        
        return newFiles?.length > FileListing.Empty ? newFiles : [];
      }

      return [];
    });
  };

  const isFileInFiles = (files: ActionHistoryFileType[] | null | undefined, fileRemoveUuid: string): boolean => {
    return Array.isArray(files)
      ? files.some(({ uuid }): boolean => uuid === fileRemoveUuid)
      : false;
  };

  const handleRemoveKNAP = async (
    e: React.MouseEvent<HTMLElement>,
    fileRemove: UploadedFilesPropsType
  ): Promise<void> => {
    e.preventDefault();
    const isFileInDocumentTaskFiles: boolean = isFileInFiles(
      documentTask?.fields?.files,
      fileRemove.uuid
    );
    const isFileInActionHistoryFiles: boolean = isFileInFiles(
      actionHistory?.results?.[0]?.fields?.files,
      fileRemove.uuid
    );

    if (isFileInDocumentTaskFiles || isFileInActionHistoryFiles) {
      removeFileNAPLocale(fileRemove);

      return;
    }

    try {
      const params: URLSearchParams = new URLSearchParams();
      params.append("file_uuid", fileRemove?.uuid);

      const response = await deleteUploadFile(params.toString());
      message.success(response.data.message);

      removeFileNAPLocale(fileRemove);
    } catch (error) {
      message.error("Файл не удален!");
    }
  };

  const uploadedFilesKNAPList: JSX.Element[] | null = uploadedFilesKNAP?.length
    ? uploadedFilesKNAP.map((file: UploadedFilesPropsType) => (
        <div key={file?.uuid} className={css.documentsAction}>
          <div className="flex items-center pl-0.5">
            <AttachFileIcon className="flex-shrink-0" />
            <a className={css.fileName} onClick={() => downloadFile(file?.file_name, downloadUrl(file.uuid))}>
              {file?.file_name}
            </a>
          </div>
          <div className={css.deleteIcon} onClick={(e: React.MouseEvent<HTMLElement>) => handleRemoveKNAP(e, file)}>
            <DeleteDocument className="flex-shrink-0" />
          </div>
        </div>
      ))
    : null;

  const sendFilesToKnap = (): void => {
    const knapBody: TypicalTaskDecisionType = {
      task: taskUuid,
      task_decision_type: "knap_decision",
      files: uploadedFilesKNAP?.length ? uploadedFilesKNAP?.map((file: UploadedFilesPropsType) => file.uuid) : []
    };

    onFinish(knapBody);
  };

  const renderKnapText = (hasFiles: boolean): JSX.Element =>
    hasFiles ? (
      <div className={`${css.modalText} mb-5`}>
        Ниже представлены файлы, которые содержались в задаче или были отправлены клиентом. Вы можете скорректировать
        список, удалив ненужные файлы. Если необходимо дополнить список, загрузите файлы вручную.
      </div>
    ) : (
      <div className={css.noFiles}>
        <WarningIcon className="flex-shrink-0 w-6 h-6" />
        <div className={css.noFilesText}>
          Новых документов для распознавания не найдено. Если необходимо, загрузите документы для распознавания вручную
        </div>
      </div>
    );

  return (
    <Form onFinish={sendFilesToKnap}>
      {renderKnapText(!!uploadedFilesKNAP?.length)}
      {!showUploadKNAP && (
        <DraggerForm
          props={{ ...propsKNAP }}
          fileCount={uploadedFilesKNAP?.length as number}
          counterUpload={counterUploadKNAP}
          fileSize={maxSizeFile}
        />
      )}
      <div className={`${css.fileListing} grid justify-items-start items-center`}>
        {uploadFilesLoaderKNAP ? (
          <Skeleton
            active
            title={false}
            paragraph={{
              rows: 1,
              width: "100%"
            }}
          />
        ) : (
          uploadedFilesKNAPList
        )}
      </div>
      <Form.Item noStyle>
        <div className="mt-4 text-right">
          <ButtonCustom
            className="mr-2"
            size="large"
            type="default"
            ghost
            text="Отменить"
            onClick={onDrawerClose}
          />
          <ButtonCustom
            size="large"
            type="primary"
            text="Подтвердить"
            htmlType="submit"
            disabled={!uploadedFilesKNAP.length}
          />
        </div>
      </Form.Item>
    </Form>
  );
};

export default RecognizeKnap;
