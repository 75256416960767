import React, {
  FC,
  JSX,
  Dispatch,
  ChangeEvent,
  SetStateAction,
} from "react";
import { TypicalTaskDecisionType, UploadedFilesPropsType } from "app/types";
import { ButtonCustom } from "../../../../../ui-kit/ButtonCustom/ButtonCustom";
import DrawerDragAndDrop from "./DrawerDragAndDrop/DrawerDragAndDrop";
import {
  Form,
  Input,
  FormInstance,
} from "antd";

interface ISendClarificationProps {
  form: FormInstance;
  taskUuid: string;
  onFinish: (actionBody: TypicalTaskDecisionType) => void;
  onDrawerClose: () => void;
  dragAndDropFiles: UploadedFilesPropsType[];
  drawerTaskAction: string;
  taskActionComment: string;
  uploadedFilesList: JSX.Element[] | null;
  setDragAndDropFiles: Dispatch<SetStateAction<UploadedFilesPropsType[]>>;
  markupTaskFileFrom1C: UploadedFilesPropsType[] | null;
  handleTextAreaChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  remainingCharsComment: number;
  dragAndDropFilesCount: number;
  setDragAndDropFilesCount: Dispatch<SetStateAction<number>>;
}

const { TextArea } = Input;

const SendClarification: FC<ISendClarificationProps> = ({
  form,
  taskUuid,
  onFinish,
  onDrawerClose,
  dragAndDropFiles,
  drawerTaskAction,
  taskActionComment,
  uploadedFilesList,
  setDragAndDropFiles,
  handleTextAreaChange,
  remainingCharsComment,
  dragAndDropFilesCount,
  setDragAndDropFilesCount,
}): JSX.Element => {
  const isManualOrClarificationActionType = drawerTaskAction === "Отправить на уточнение"
    ? "clarification_decision"
    : "manual_decision";

  const sendClarificationBody: TypicalTaskDecisionType = {
    task: taskUuid,
    task_decision_type: isManualOrClarificationActionType,
    comment: taskActionComment,
    files: dragAndDropFiles?.map(({ uuid }) => uuid) ?? []
  };

  const onSendClarificationFinish = (): void => {
    onFinish(sendClarificationBody);
  };

  return (
    <Form
      className="m-0"
      name="sendClarification"
      form={form}
      onFinish={onSendClarificationFinish}
    >
      <Form.Item
        className="mb-4"
        name="clarificationComment"
      >
        <TextArea
          id="clarificationComment"
          rows={25}
          maxLength={2000}
          className="text-base font-normal leading-5 text-gray-900"
          placeholder="Ваш комментарий клиенту"
          onChange={handleTextAreaChange}
        />
        <div className="text-xs font-normal leading-100 counter-gray-color text-right">
          {remainingCharsComment}/2000
        </div>
      </Form.Item>
      <Form.Item
        className="mb-4"
        name="clarificationDocs"
      >
        <DrawerDragAndDrop
          uploadedFilesList={uploadedFilesList}
          setDragAndDropFiles={setDragAndDropFiles}
          isSuccessBlockHidden
          dragAndDropFilesCount={dragAndDropFilesCount}
          setDragAndDropFilesCount={setDragAndDropFilesCount}
        />
      </Form.Item>
      <Form.Item className="text-right">
        <ButtonCustom
          className="mr-2"
          size="large"
          type="default"
          ghost
          text="Отменить"
          onClick={onDrawerClose}
        />
        <ButtonCustom
          size="large"
          type="primary"
          text="Подтвердить"
          htmlType="submit"
          disabled={!remainingCharsComment}
        />
      </Form.Item>
    </Form>
  );
};

export default SendClarification;
